import { sumBy, findIndex } from "lodash";
import { getImpositions } from "./imposition";
import { countGrid, CUTTING_OPERATION, VARNISH_OPERATION, LAMINATION_OPERATION, FOLDING_OPERATION, MANUAL_COLLATION_OPERATION, PLATES_STORAGE_OPERATION, evaluateInContext, SWEING_OPERATION, } from "./utils";
export function getComponent(request, context, database) {
    const impositions = getBestImpositions(request, context, database);
    const tasks = getComponentTasks(request, impositions, database.printersById);
    return { impositions, tasks };
}
function getComponentTasks(request, impositions, printersById) {
    const tasks = [];
    // const hasPrint = request.frontColors || request.backColors;
    if (request.plates) {
        tasks.push(getPlatesTask(request, impositions, printersById));
    }
    if (request.largeSheetCutting) {
        tasks.push(getLargeSheetCuttingTask(request, impositions));
    }
    if (request.simpleSheetCutting) {
        tasks.push(getSimpleSheetCuttingTask(request, impositions));
    }
    if (request.printing) {
        tasks.push(getPrintingTask(request, impositions, printersById));
    }
    if (request.platesStorage) {
        tasks.push(getPlateStorageTask(request, impositions));
    }
    if (request.varnish) {
        tasks.push(getVarnishTask(request, impositions));
    }
    if (request.lamination) {
        tasks.push(getLaminationTask(request, impositions));
    }
    if (request.printSheetCutting) {
        tasks.push(getPrintSheetCuttingTask(request, impositions));
    }
    if (request.folding) {
        tasks.push(getFoldingTask(request, impositions));
    }
    if (request.manualCollation) {
        tasks.push(getManualCollationTask(request, impositions));
    }
    if (request.sewing) {
        tasks.push(getSewingTask(request, impositions));
    }
    return tasks;
}
function getBestImpositions(request, context, database) {
    const pages = evaluateInContext(request.pages, context);
    const options = getImpositions(request, context, database).map((imposition) => {
        const tasks = getComponentTasks(request, [imposition], database.printersById);
        const price = sumBy(tasks, (task) => getTaskPrice(task, context, database));
        return { imposition, price };
    });
    const cache = {};
    const findSolution = (pages) => {
        if (cache[pages])
            return cache[pages];
        let bestSolution = { impositions: [], price: Infinity };
        for (const option of options) {
            let currentSolution = {
                impositions: [option.imposition],
                price: option.price,
            };
            const imposition = option.imposition;
            const remainingPages = pages - imposition.distinctPages;
            if (remainingPages > 0) {
                const nestedSolution = findSolution(remainingPages);
                currentSolution = {
                    price: currentSolution.price + nestedSolution.price,
                    impositions: [].concat(currentSolution.impositions, nestedSolution.impositions),
                };
            }
            if (currentSolution.price < bestSolution.price) {
                bestSolution = currentSolution;
            }
        }
        cache[pages] = bestSolution;
        return bestSolution;
    };
    return findSolution(pages).impositions;
}
//
function getPlatesTask(request, impositions, printersById) {
    const { frontColors, backColors } = request;
    const maxColors = Math.max(frontColors, backColors);
    return {
        name: "Executare Matrite",
        operations: impositions.map(({ shouldTurn, printerId }) => ({
            operationId: printersById[printerId].data.plateOperationId,
            quantity: shouldTurn ? maxColors : frontColors + backColors,
            parameter: 1,
        })),
        materials: impositions.map(({ shouldTurn, printerId }) => ({
            materialId: printersById[printerId].data.plateMaterialId,
            quantity: shouldTurn ? maxColors : frontColors + backColors,
        })),
    };
}
function getLargeSheetCuttingTask(request, impositions) {
    return {
        name: "Formatizare 1",
        operations: impositions.map(({ copies, largeSheetLayout }) => {
            const printSheetsOnLargeSheet = countGrid(largeSheetLayout);
            return {
                operationId: CUTTING_OPERATION,
                quantity: `ceil(printSheets(${copies} / ${request.copies}) / ${printSheetsOnLargeSheet})`,
                parameter: printSheetsOnLargeSheet,
            };
        }),
        materials: impositions.map(({ copies, largeSheetLayout, largeSheetId }) => {
            const printSheetsOnLargeSheet = countGrid(largeSheetLayout);
            return {
                materialId: largeSheetId,
                quantity: `ceil(printSheets(${copies} / ${request.copies}) / ${printSheetsOnLargeSheet})`,
            };
        }),
    };
}
function getSimpleSheetCuttingTask(request, impositions) {
    return {
        name: "Formatizare Simpla",
        operations: impositions.map(({ printSheetLayout, largeSheetLayout }) => {
            const finalSheetsOnLargeSheet = countGrid(printSheetLayout) * countGrid(largeSheetLayout);
            return {
                operationId: CUTTING_OPERATION,
                quantity: `ceil(quantity * ${request.copies} / ${finalSheetsOnLargeSheet})`,
                parameter: finalSheetsOnLargeSheet,
            };
        }),
        materials: impositions.map(({ printSheetLayout, largeSheetLayout, largeSheetId }) => {
            const finalSheetsOnLargeSheet = countGrid(printSheetLayout) * countGrid(largeSheetLayout);
            return {
                materialId: largeSheetId,
                quantity: `ceil(quantity * ${request.copies} / ${finalSheetsOnLargeSheet})`,
            };
        }),
    };
}
function getPrintingTask(request, impositions, printersById) {
    const { frontColors, backColors } = request;
    const maxColors = Math.max(frontColors, backColors);
    return {
        name: "Tiparire",
        operations: impositions.flatMap(({ copies, shouldTurn, printerId }) => {
            const results = [];
            const printer = printersById[printerId];
            if (shouldTurn) {
                if (maxColors > 0) {
                    results.push({
                        operationId: printer.data.printingOperationId,
                        quantity: `ceil(printSheets(${copies} / ${request.copies}) * 2)`,
                        parameter: maxColors,
                    });
                }
            }
            else {
                if (frontColors > 0) {
                    results.push({
                        operationId: printer.data.printingOperationId,
                        quantity: `ceil(printSheets(${copies} / ${request.copies}))`,
                        parameter: frontColors,
                    });
                }
                if (backColors > 0) {
                    results.push({
                        operationId: printer.data.printingOperationId,
                        quantity: `ceil(printSheets(${copies} / ${request.copies}))`,
                        parameter: backColors,
                    });
                }
            }
            return results;
        }),
        materials: request.largeSheetCutting
            ? []
            : impositions.map(({ copies, largeSheetLayout, largeSheetId }) => {
                const printSheetsOnLargeSheet = countGrid(largeSheetLayout);
                return {
                    materialId: largeSheetId,
                    quantity: `ceil(printSheets(${copies} / ${request.copies}) / ${printSheetsOnLargeSheet})`,
                };
            }),
    };
}
function getPlateStorageTask(request, impositions) {
    const { frontColors, backColors } = request;
    const maxColors = Math.max(frontColors, backColors);
    return {
        name: "Conservare Matrite",
        operations: impositions.map(({ shouldTurn }) => ({
            operationId: PLATES_STORAGE_OPERATION,
            quantity: 1,
            parameter: shouldTurn ? maxColors : frontColors + backColors,
        })),
        materials: [],
    };
}
function getVarnishTask(request, impositions) {
    return {
        name: "Lacuire",
        operations: impositions.map(({ copies }) => ({
            operationId: VARNISH_OPERATION,
            quantity: `ceil(printSheets(${copies} / ${request.copies}))`,
            parameter: 1,
        })),
        materials: [],
    };
}
function getLaminationTask(request, impositions) {
    return {
        name: "Foliere",
        operations: impositions.map(({ copies, finalSheetSize }) => ({
            operationId: LAMINATION_OPERATION,
            quantity: `ceil(printSheets(${copies} / ${request.copies}))`,
            parameter: Math.round((finalSheetSize.width * finalSheetSize.height) / 100),
        })),
        materials: [],
    };
}
function getPrintSheetCuttingTask(request, impositions) {
    return {
        name: "Formatizare 2",
        operations: impositions.map(({ copies, printSheetLayout }) => {
            const finalSheetsOnPrintSheet = countGrid(printSheetLayout);
            return {
                operationId: CUTTING_OPERATION,
                quantity: `ceil(printSheets(${copies} / ${request.copies}))`,
                parameter: finalSheetsOnPrintSheet,
            };
        }),
        materials: [],
    };
}
function getFoldingTask(request, impositions) {
    return {
        name: "Faltuire",
        operations: impositions.map(({ copies, printSheetLayout, finalSheetLayout }) => {
            const finalSheetsOnPrintSheet = countGrid(printSheetLayout);
            const pagesOnFinalSheet = countGrid(finalSheetLayout) * 2;
            return {
                operationId: FOLDING_OPERATION,
                quantity: `ceil(printSheets(${copies} / ${request.copies}) * ${finalSheetsOnPrintSheet})`,
                parameter: pagesOnFinalSheet,
            };
        }),
        materials: [],
    };
}
function getManualCollationTask(request, impositions) {
    return {
        name: "Adunare Manuala",
        operations: impositions.map(({ copies, distinctFinalSheets }) => ({
            operationId: MANUAL_COLLATION_OPERATION,
            quantity: `ceil(printSheets(${copies} / ${request.copies}))`,
            parameter: distinctFinalSheets,
        })),
        materials: [],
    };
}
function getSewingTask(request, impositions) {
    return {
        name: "Coasere",
        operations: [
            {
                operationId: SWEING_OPERATION,
                quantity: `quantity`,
                parameter: `${sumBy(impositions, (i) => i.distinctFinalSheets)} * ${request.copies}`,
            },
        ],
        materials: [],
    };
}
//
export function getTaskPrice(task, context, database) {
    const operations = sumBy(task.operations, (taskOperation) => getTaskOperationPrice(taskOperation, context, database.operationsById));
    const materials = sumBy(task.materials, (taskMaterial) => getTaskMaterialPrice(taskMaterial, context, database.materialsById));
    return operations + materials;
}
export function getTaskOperationPrice(taskOperation, context, operationsById) {
    const quantity = evaluateInContext(taskOperation.quantity, context);
    const parameter = evaluateInContext(taskOperation.parameter, context);
    const operation = operationsById[taskOperation.operationId];
    //hotfix nu lasa creeat componenta
    if (operation) {
        return quantity * getOperationPrice(quantity, parameter, operation);
    }
    return 0;
}
export function getTaskMaterialPrice(taskMaterial, context, materialsById) {
    const quantity = evaluateInContext(taskMaterial.quantity, context);
    const material = materialsById[taskMaterial.materialId];
    return quantity * material.data.price;
}
function getOperationPrice(quantity, parameter, operation) {
    const { quantityBounds, parameterBounds, prices } = operation.data;
    const quantityIndex = findIndex(quantityBounds, (b) => b >= quantity);
    const quantityResult = quantityIndex === -1 ? quantityBounds.length - 1 : quantityIndex;
    const parameterIndex = findIndex(parameterBounds, (b) => b >= parameter);
    const parameterResult = parameterIndex === -1 ? parameterBounds.length - 1 : parameterIndex;
    const priceIndex = parameterResult * quantityBounds.length + quantityResult;
    return prices[priceIndex];
}
//
export function getMaterialQuantityTotals(tasks, context) {
    const result = {};
    tasks.forEach((task) => {
        if (task.materials) { // hotfix raul - exista taskuri fara materiale ?
            task.materials.forEach((taskMaterial) => {
                const { materialId } = taskMaterial;
                const quantity = evaluateInContext(taskMaterial.quantity, context);
                result[materialId] = (result[materialId] || 0) + quantity;
            });
        }
    });
    return result;
}
export function normalizeComponentRequest(request, printers, largeSheets) {
    return {
        ...request,
        printerIds: request.printerIds ||
            getAppropriatePrinters(request, printers).map((printer) => printer.id),
        largeSheetIds: request.largeSheetIds ||
            getAppropriateLargeSheets(request, largeSheets).map((sheet) => sheet.id),
    };
}
export function getAppropriatePrinters(request, printers) {
    const maxColors = Math.max(request.frontColors, request.backColors);
    return printers.filter((printer) => printer.data.colors >= maxColors);
}
export function getAppropriateLargeSheets(request, largeSheets) {
    return largeSheets.filter((sheet) => sheet.data.categoryId === request.sheetCategoryId &&
        sheet.data.weight === request.sheetWeight);
}
