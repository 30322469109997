import { evaluate } from "mathjs";
export function scaleRectByGrid(rect, grid) {
    return {
        width: rect.width * grid.columns,
        height: rect.height * grid.rows,
    };
}
export function splitRectByGrid(rect, grid) {
    return {
        width: rect.width / grid.columns,
        height: rect.height / grid.rows,
    };
}
export function splitRectByRect(largeRect, smallRect, roundFn = Math.floor) {
    return {
        columns: roundFn(largeRect.width / smallRect.width),
        rows: roundFn(largeRect.height / smallRect.height),
    };
}
export function rotateRect(rect) {
    return { width: rect.height, height: rect.width };
}
export function rotatePrinter(printer) {
    const { id, data } = printer;
    return {
        id,
        data: {
            ...data,
            minWidth: data.minHeight,
            maxWidth: data.maxHeight,
            minHeight: data.minWidth,
            maxHeight: data.maxWidth,
            marginTop: data.marginRight,
            marginBottom: data.marginLeft,
            marginLeft: data.marginTop,
            marginRight: data.marginBottom,
        },
    };
}
export function countGrid(grid) {
    return grid.columns * grid.rows;
}
export function rectFitsPrinter(rect, printer) {
    return (rect.width >= printer.data.minWidth &&
        rect.width <= printer.data.maxWidth &&
        rect.height >= printer.data.minHeight &&
        rect.height <= printer.data.maxHeight);
}
export function removeMarginsFromRect(rect, printer) {
    const { data } = printer;
    return {
        width: rect.width - data.marginLeft - data.marginRight,
        height: rect.height - data.marginTop - data.marginBottom,
    };
}
export function evaluateInContext(expr, context) {
    if (typeof expr === "number")
        return expr;
    const { quantity, surplus_base, surplus_rise } = context;
    const printSheets = (copies) => (quantity / copies) * surplus_rise + surplus_base;
    return evaluate(expr, { ...context, printSheets });
}
export function getContextFromVariables(variables) {
    return variables.reduce((context, variable) => {
        context[variable.name] = evaluate(variable.value, context);
        return context;
    }, {});
}
//tot timpu e necesar sa ordonezi proprietatile cand faci compare cu JSON.stringigy !
function orderObjectKeys(unordered) {
    return Object.keys(unordered).sort().reduce((obj, key) => {
        obj[key] = unordered[key];
        return obj;
    }, {});
}
export function countUniques(array) {
    const index = array.reduce((index, item) => {
        const serialized = JSON.stringify(orderObjectKeys(item));
        if (index[serialized] === undefined) {
            index[serialized] = 0;
        }
        index[serialized] += 1;
        return index;
    }, {});
    return Object.entries(index).map((entry) => ({
        value: JSON.parse(entry[0]),
        count: entry[1],
    }));
}
//
export const DESIGN_OPERATION = "AMaZ3nvV5GkmvN86IkN4";
export const CUTTING_OPERATION = "YLLM6wWQL7khvKY9GjSK";
export const FOLDING_OPERATION = "2jxgxzA0wWgC0R8tGgl9";
export const CHECKING_OPERATION = "h47MXUOmXGXM1H6euZsD";
export const PACKING_OPERATION = "mhkMLgnMMQvMfabN8qPO";
export const SHIPPING_OPERATION = "RVvGVfWfpiOCBYXyEmv5";
export const ROUNDING_OPERATION = "a9OikZvP1WRSVHiLguDr";
export const PERFECT_BINDING_OPERATION = "oekBy0CCCbzByOfj0d1J";
export const MANUAL_COLLATION_OPERATION = "5xMTmWSepDR4xfVETBeH";
export const VARNISH_OPERATION = "EQJLnN71NRuNDg4KvEqq";
export const LAMINATION_OPERATION = "WmxF9JwllWGk9iNObP0R";
export const SWEING_OPERATION = "B2raQWNkBI7YdP3VphNr";
export const OFFSET_SHEET_CATEGORY = "gFlyF5FON8zYVOxxLapa";
export const MATTE_CARDBOARD_SHEET_CATEGORY = "kneVzVI5N9NtdzQG2Ihz";
export const GLOSSY_CARDBOARD_SHEET_CATEGORY = "zeIfm7sFicTJLtt1pZBD";
export const PLATES_STORAGE_OPERATION = "5s73ZNsRa3GoSLSN2fBs";
export const baseComponentRequest = {
    // format
    width: "width",
    height: "height",
    // paper
    sheetCategoryId: null,
    sheetWeight: null,
    largeSheetIds: null,
    // print
    frontColors: 1,
    backColors: 1,
    printerIds: null,
    // content
    finalSheetLayouts: null,
    pages: null,
    copies: 1,
    // extra
    varnish: false,
    lamination: false,
    creasing: false,
    plates: true,
    platesStorage: false,
    folding: false,
    manualCollation: false,
    printing: true,
    largeSheetCutting: true,
    printSheetCutting: true,
    simpleSheetCutting: false,
    sewing: false,
};
// export const baseTask = {
//   type: "task",
//   name: "???",
//   operations: [],
//   materials: [],
//   active: false,
//   completed: false,
//   alert: null,
//   imageUrl: null,
//   employeeIds: []
// };
export const taskInstanceBase = {
    active: false,
    completed: false,
    result: null,
    materialsDebited: false,
    alert: null,
    imageUrl: null,
    employeeIds: [],
};
