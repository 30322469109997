import { countGrid, splitRectByRect, scaleRectByGrid, splitRectByGrid, rotatePrinter, rectFitsPrinter, evaluateInContext, removeMarginsFromRect, } from "./utils";
export function getImpositions(request, context, database) {
    const { width, height, largeSheetIds, printerIds, frontColors, backColors, } = request;
    const pageSize = {
        width: evaluateInContext(width, context),
        height: evaluateInContext(height, context),
    };
    const finalSheetLayouts = request.finalSheetLayouts.map((finalSheetLayout) => {
        const tokens = finalSheetLayout.split("x");
        return { columns: Number(tokens[0]), rows: Number(tokens[1]) };
    });
    const isTwoSided = frontColors > 0 && backColors > 0;
    return getImpositionConstraints(largeSheetIds, printerIds, finalSheetLayouts)
        .flatMap((constraints) => getImpositionLayouts(constraints, pageSize, database))
        .flatMap((layout) => getImpositionStrategies(layout, isTwoSided));
}
function getImpositionConstraints(largeSheetIds, printerIds, finalSheetLayouts) {
    const constraints = [];
    printerIds.forEach((printerId) => {
        // /hotfix raul era null
        if (largeSheetIds) {
            largeSheetIds.forEach((largeSheetId) => {
                finalSheetLayouts.forEach((finalSheetLayout) => {
                    constraints.push({
                        largeSheetId,
                        printerId,
                        finalSheetLayout,
                    });
                });
            });
        }
    });
    return constraints;
}
function getImpositionLayouts(constraints, pageSize, database) {
    const { materialsById, printersById } = database;
    const { finalSheetLayout, largeSheetId, printerId } = constraints;
    const largeSheet = materialsById[largeSheetId];
    const printer = printersById[printerId];
    const layouts = [];
    const finalSheetSize = scaleRectByGrid(pageSize, finalSheetLayout);
    const largeSheetSize = {
        width: largeSheet.data.width,
        height: largeSheet.data.height,
    };
    for (let columns = 1; columns <= 4; columns++) {
        for (let rows = 1; rows <= 4; rows++) {
            const largeSheetLayout = { columns, rows };
            const printSheetSize = splitRectByGrid(largeSheetSize, largeSheetLayout);
            const printSheetLayout = getPrintSheetLayout(finalSheetSize, printSheetSize, printer);
            if (printSheetLayout === null)
                continue;
            layouts.push({
                ...constraints,
                pageSize,
                finalSheetSize,
                printSheetSize,
                largeSheetSize,
                finalSheetLayout,
                printSheetLayout,
                largeSheetLayout,
            });
        }
    }
    return layouts;
}
function getPrintSheetLayout(finalSheetSize, printSheetSize, printer) {
    const press = printSheetSize.width > printSheetSize.height
        ? printer
        : rotatePrinter(printer);
    if (!rectFitsPrinter(printSheetSize, press))
        return null;
    const sizeWithoutMargins = removeMarginsFromRect(printSheetSize, press);
    return splitRectByRect(sizeWithoutMargins, finalSheetSize);
}
function getImpositionStrategies(layout, isTwoSided) {
    const pages = countGrid(layout.finalSheetLayout) * 2;
    const finalSheets = countGrid(layout.printSheetLayout);
    const impositions = [];
    const canTurn = finalSheets % 2 === 0;
    for (let i = 1; i <= finalSheets; i++) {
        impositions.push({
            ...layout,
            shouldTurn: false,
            distinctFinalSheets: i,
            distinctPages: pages * i,
            copies: Math.floor(finalSheets / i),
        });
    }
    if (isTwoSided && canTurn) {
        for (let j = 1; j <= finalSheets / 2; j++) {
            impositions.push({
                ...layout,
                shouldTurn: true,
                distinctFinalSheets: j,
                distinctPages: pages * j,
                copies: Math.floor(finalSheets / j),
            });
        }
    }
    return impositions;
}
