import cytoscape from "cytoscape";

export function getProjectGraph(projectData) {
  const mapper = data => ({ data });
  const graph = cytoscape({
    elements: [
      ...Object.values(projectData.components).map(mapper),
      ...Object.values(projectData.tasks).map(mapper),
      ...Object.values(projectData.edges).map(mapper)
    ]
  });
  return Object.freeze(graph);
}

export function getProjectGraphElements(projectData) {
  const mapper = data => ({ data });
  return [
    ...Object.values(projectData.components).map(mapper),
    ...Object.values(projectData.tasks).map(mapper),
    ...Object.values(projectData.edges).map(mapper)
  ];
}

export const graphStyle = [
  {
    selector: "node",
    style: {
      label: "data(name)",
      width: 20,
      height: 20,
      "border-width": 2
    }
  },
  {
    selector: "edge",
    style: {
      "curve-style": "bezier",
      "target-arrow-shape": "triangle",
      "target-endpoint": "outside-to-node-or-label"
    }
  },
  {
    selector: "node[?active]",
    style: { "background-color": "#ECC94B" }
  },
  {
    selector: "node[?completed]",
    style: { "background-color": "#48BB78" }
  },
  { selector: "edge[?hidden]", style: { width: 0 } },
  {
    selector: "node[type='task']:selected",
    style: { "background-color": "#3182CE" }
  },
  {
    selector: "edge:selected",
    style: { "line-color": "#3182CE" }
  }
];
